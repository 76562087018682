<template>
    <b-card style="border-radius: 10px;" class="pb-0 mb-0 meetings">
        <div style="font-size: 2rem; color: #84b0dd; font-weight: 800;" class="mb-2">
            e-sestanki
        </div>

        <div style="font-size: 1.5rem; font-weight: 900;">
            Prihajajoči e-sestanki
        </div>

        <b-table class="table mt-1" :responsive="true" style="position: relative;" striped :items="rooms" :fields="fields">
            <template #cell(start_time)="row">
                <span style="width: 50px;">{{dayjs(row.item.start_time).format('DD.MM.YYYY')}}</span>
            </template>
            <template #cell(time)="row">
                {{dayjs(row.item.start_time).format('H:mm')}}
            </template>
            <template #cell(actions)="row">
                <div class="d-flex justify-content-end" v-if="row.item.can_join">
                    <rounded-button v-on:click="joinMeeting(row.item)" :disabled="!row.item.can_join" :id="'tooltip_' + row.item.id" padding="0.5em 1em">
                        VKLJUČI SE <fa icon="video" class="ml-1" />
                    </rounded-button>
                </div>
                <div v-if="!row.item.can_join" class="d-flex justify-content-end" style="height: 30px;">
                    Sestanek se še ni začel
                </div>
            </template>
            <template v-if="rooms && rooms.length === 0" slot="bottom-row">
                <b-td :colspan="fields.length">
                    <span class="d-flex justify-content-center">Ni podatkov za prikaz</span>
                </b-td>
            </template>
        </b-table>

        <div style="font-size: 1.5rem; font-weight: 900;" class="mt-3">
            Pretekli e-sestanki
        </div>

        <b-table class="table table-2 mt-1" :responsive="true" style="position: relative;" striped :items="finishedRooms" :fields="fields">
            <template #cell(start_time)="row">
                <span>{{dayjs(row.item.start_time).format('DD.MM.YYYY')}}</span>
            </template>
            <template #cell(time)="row">
                {{dayjs(row.item.start_time).format('H:mm')}}
            </template>
            <template #cell(actions)="row">
                <div class="meetings-presence d-flex justify-content-end">
                    <b-button class="d-flex align-items-center justify-content-center" variant="outline-danger" v-if="!row.item.attended">
                        Niste se udeležili
                    </b-button>
                    <b-button class="d-flex align-items-center justify-content-center" variant="outline-success" v-if="row.item.attended">
                        Udeležili ste se
                    </b-button>
                </div>
            </template>
            <template v-if="finishedRooms && finishedRooms.length === 0" slot="bottom-row">
                <b-td :colspan="fields.length">
                    <span class="d-flex justify-content-center">Ni podatkov za prikaz</span>
                </b-td>
            </template>
        </b-table>
    </b-card>
</template>

<script>
    import { BButton, BCard, BTable, BTd} from 'bootstrap-vue'
    import RoundedButton from '@/views/Components/RoundedButton.vue'
    export default {
        components:{
            BButton,
            BCard,
            RoundedButton,
            BTable,
            BTd
        },
        data() {
            return {
                disabled: false,
                token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJCRUU2MiIsImNvbnRleHQiOnsidXNlciI6eyJlbWFpbCI6ImFkbWluQGl0LXRpbS5zaSIsImlkIjoiNjBjYzk4NDE1ZjJhYmRlYWM3MmI3OTUyUWxlUVloWXpSeSIsIm5hbWUiOiJBZG1pbiBBZG1pbiBRbGVRWWhZelJ5In19LCJpYXQiOiIyMDIyLTAyLTE0VDIwOjQ4OjQ2LjcyOTQwODgrMDE6MDAiLCJpc3MiOiJCRUU2MiIsInJvb20iOiJkZW1vIiwic3ViIjoibWVldC5qaXQuc2kiLCJ2YWxpZFVudGlsIjoiMjAyMi0wMi0xNFQyMDo0ODo0Ni43Mjk0MDg4KzAxOjAwIn0.wKNGx7BtUnz7qFId8a2ZrOhHgXZvaeN_wU3zoSVtDd0',
                jitsiOptions: {
                    roomName: 'demo',
                    jwt: null,
                    height: 500
                },
                time: null,
                meetingName: '',
                rooms: null,
                finishedRooms: [],
                fields: [
                    { key: 'name', label: 'Sestanek', sortable: false, class: 'text-left custom-text' },
                    { key: 'start_time', label: 'Datum', sortable: false, class: 'text-left' },
                    { key: 'time', label: 'Ura začetka', sortable: false, class: 'text-left' },
                    { key: 'actions', label: '', sortable: false, class: 'text-right' }
                ]
            }
        },
        methods:{
            joinMeeting(room) {
                this.$router.push({ name: 'meeting', params: { 'room_id': room.id}})
            },
            async createMeeting() {

                const startTime = new Date(this.time)
                startTime.setHours(18, 0, 0)

                const endTime = new Date(startTime)
                endTime.setHours(endTime.getHours() + 1)

                const payload = {
                    'name': this.meetingName,
                    'start_time': startTime,
                    'expiration_time': endTime
                }
                
                await this.$http.post('/api/user/v1/jitsi/createMeeting', payload)

                this.updateMeetings()
            },
            async updateMeetings() {

                try {
                    const data = await this.$http.get('/api/user/v1/jitsi/upcoming_meetings')
                    if (data && data.data) {
                        this.rooms = data.data
                    }

                    const data1 = await this.$http.get('/api/user/v1/jitsi/finished_meetings')
                    if (data1 && data1.data) {
                        this.finishedRooms = data1.data
                    }
                    
                } catch (err) {
                    this.$printError('Prislo je do napake')
                }
                 
            }
        },
        mounted() {
            this.updateMeetings()
        }
    }
</script>

<style scoped>
    .meetings-presence .btn {
        width: 130px;
        border-radius: 20px;
        margin: 6px 0;
        padding: 0.2em;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
    }
     .btn-outline-danger {
        border: 2px solid #C60D0D !important;
        color: #C60D0D !important;
    }
    .btn-outline-success {
        border: 2px solid #62B014 !important;
        color: #62B014 !important;
    }
</style>
<style>
.meetings .table th {
  background-color: #dbe9f5 !important;
  border-top: none;
  border-bottom: none;
  text-transform: none;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  font-size: 17px;
}
.meetings .table thead tr {
  border-bottom: 2px solid #72a5d8;
  border-right: 30px solid #dbe9f5 !important;
  border-left: 30px solid #dbe9f5 !important;
  color: #72a5d8 !important;
  font-weight: bold;
  background: #dbe9f5 !important;
}
.meetings .table tbody tr {
  background-color: #eaf3f9 !important;
  border-top: 1px solid #72a5d87e;
  border-right: 30px solid #eaf3f9;
  border-left: 30px solid #eaf3f9;
}
.meetings .table tfoot {
  background-color: #eaf3f9 !important;
  border-top: 2px solid #72a5d87e;
  border-right: 30px solid #eaf3f9;
  border-left: 30px solid #eaf3f9;
  border-bottom: 20px solid #eaf3f9;
  font-weight: bold;
}
.meetings .table td {
  border-top: 1px solid #72A5D880;
}
.meetings .table td, .meetings .table th {
  padding: 0.72rem 0.8rem !important;
}
.meetings .table-2 thead {
  display: none !important;
}
.meetings .table-2 tbody tr:first-child td {
  border-color: #eaf3f9 !important;
}
.meetings .table td[aria-colindex='1'], .meetings .table th[aria-colindex='1'] {
  width: 300px !important;
  padding: 0.72rem 0.2rem !important;
}
.meetings .table td[aria-colindex='4'], .meetings .table th[aria-colindex='4'] {
  padding: 0.72rem 0.2rem !important;
}
.meetings .table td[aria-colindex='2'], .meetings .table th[aria-colindex='2'] {
  width: 150px !important;
}
</style>